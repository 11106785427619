import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { useMemo, useState } from 'react';
import NavBar from './Components/Navbar';


const globalTheme = (mode) => ({
    palette: {
        mode,
        ...(mode === "light") ?
        {
            primary: {
                main: '#fbd504ff'
            },

        } :
        {
            primary: {
                main: '#9090f6ff'
            },
            background: {
                default: "#222222"
            }
        }

    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained'
            },
        },
    },
})



function App() {
    const [ mode, setMode ] = useState('light')


    const handleToggleDarkMode = (e) => {
        if(e.target.checked) {
            setMode('dark')
            return
        }
        setMode('light')
    }

    const theme = useMemo(() => createTheme(globalTheme(mode)), [mode]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <NavBar onDarkModeToggle={handleToggleDarkMode} darkMode={mode === 'dark'}/>
        </ThemeProvider>
    );
}

export default App;

import { Email } from "@mui/icons-material"
import { Box, Button, FormControlLabel, Stack, Switch } from "@mui/material"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import logoLight from "./../logoLight.svg"
import logoDark from "./../logoDark.svg"

const NavBarContainer = styled(Box)`
    height: 60px;
    -webkit-box-shadow: 0px 0px 7px 0px #000;
    -moz-box-shadow: 0px 0px 7px 0px #000;
    box-shadow: 0px 0px 7px 0px #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: absolute;
    width: 100%;
`

const NavBarLink = styled(Link)`
    cursor: pointer;
    color: black;
    text-decoration: none;
`


export default function NavBar({onDarkModeToggle, darkMode}) {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;
    console.log("darkMode", darkMode)
    return (
        <NavBarContainer>
            <NavBarLink to={'/'}><img style={{maxHeight:'40px'}} src={darkMode ? logoDark : logoLight} alt={"Scimos logo: A simplified atom with a yellow center, followed by the word 'Scimos', in which the first three letters are yelow, and they last three are grey."}/></NavBarLink>
            <Stack direction={'horizontal'}>
            <FormControlLabel
                control={<Switch checked={darkMode} onChange={onDarkModeToggle}/>}
                label={`${darkMode ? "☀️" : "🌙"} Dark mode`}
                />
                <Button><Email/>{isMobile || "Subscribe"}</Button>
            </Stack>
        </NavBarContainer>
    )
}